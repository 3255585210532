<template>
  <v-row>
    <v-col>
      <div class="mb-5">
        <v-row
          align="stretch"
          no-gutters
        >
          <v-col>
            <SearchKeyword :filter="filter" />
          </v-col>
        </v-row>
      </div>

      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-calendar</v-icon>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-toolbar-title class="d-none d-sm-flex align-center">
            <span>{{ camelToSentence($options.name) }}</span>
          </v-toolbar-title>
          <v-spacer />

          <v-btn
            color="primary"
            dark
            @click="openForm(null)"
          >
            Add
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="meta.total"
          :loading="loading"
          disable-sort
          mobile-breakpoint="0"
          :footer-props="footerProps"
        >
          <!-- name -->
          <template v-slot:[`item.name`]="{ item }">
            <span
              v-html="highlightString(item.name,routeQuery.keyword)"
            />
          </template>
          <!-- next schedule -->
          <template v-slot:[`item.next_schedule`]="{ item }">
            <span>{{ item.start_date | formatDateTime }}</span>
          </template>
          <!-- repeat every -->
          <template v-slot:[`item.repeat`]="{ item }">
            <span>{{ item.interval }} {{ item.frequency }}</span>
          </template>
          <!-- status -->
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              small
              class="ma-2"
              :color="item.is_active ? 'success' : 'error'"
              text-color="white"
            >
              {{ item.is_active ? 'Active' : 'Inactive' }}
            </v-chip>
          </template>
          <!-- actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="mr-2"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>

      <ScheduleForm
        ref="formModal"
        :item="item"
        @reload-data="getItem"
      />
    </v-col>
  </v-row>
</template>

<script>
import DatalistMixin from '../../mixins/DatalistMixin';
import SearchKeyword from '../../components/SearchKeyword.vue';
import ScheduleForm from './ScheduleForm.vue';

export default {
  name: 'ScheduleList',
  components: { SearchKeyword, ScheduleForm },
  mixins: [DatalistMixin],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: '200px',
        },
        {
          text: 'Next Schedule',
          value: 'next_schedule',
          width: '200px',
        },
        {
          text: 'Repeat Every',
          value: 'repeat',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
      filter: {
        page: 1,
        keyword: null,
      },
    };
  },

  methods: {
    getData() {
      return this.$api.getSchedules({
        ...this.filter,
      });
    },

    deleteData(schId) {
      return this.$api.deleteSchedule(schId);
    },
  },
};
</script>
