<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>

      <ErrorAlert />

      <v-card-text class="pt-5 pb-0">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            :disabled="loading"
            :counter="20"
            outlined
            dense
            label="Name"
            @keypress.enter="saveItem"
          />
          <v-autocomplete
            v-model="form.organization_id"
            :items="organizations"
            :disabled="loading"
            :rules="organizationRules"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Organization"
          />
          <v-datetime-picker
            ref="datetimePicker"
            v-model="form.start_date"
            label="Datetime"
            date-format="dd MMM yyyy"
            :text-field-props="{outlined:true,dense:true,rules:datetimeRules}"
            :time-picker-props="{format:'24hr'}"
            :date-picker-props="{noTitle:true}"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
          <v-select
            v-model="form.repeat"
            :items="repeatOptions"
            outlined
            clearable
            dense
            label="Repeat"
          />

          <v-divider class="mb-4" />
          <p>Actions</p>

          <v-select
            v-model="selectedDeviceId"
            :items="devices"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            label="Select Device"
          />
          <v-select
            v-model="selectedCommandId"
            :items="deviceCommands"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            label="Select Command"
          />
          <v-btn
            small
            class="mb-3"
            @click="addBucketItem"
          >
            Add to bucket
          </v-btn>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Device
                  </th>
                  <th class="text-left">
                    Command
                  </th>
                  <th class="text-left">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="!actionTableData.length"
                  class="text-center"
                >
                  <td colspan="3">
                    No action added to bucket
                  </td>
                </tr>
                <tr
                  v-for="action in actionTableData"
                  v-else
                  :key="action.id"
                >
                  <td>{{ action.device.device_id }}</td>
                  <td>{{ action.command.name }}</td>
                  <td>
                    <v-btn
                      icon
                      color="error"
                      @click="deleteBucketItem(action)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import FormMixin from '../../mixins/FormMixin';

export default {
  name: 'ScheduleForm',
  mixins: [FormMixin],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        name: '',
        start_date: '',
        actions: [],
        frequency: '',
        interval: '',
        is_active: 1,
        organization_id: null,
        repeat: '',
      },
      defaultForm: {
        name: '',
        start_date: '',
        actions: [],
        frequency: '',
        interval: '',
        is_active: 1,
        organization_id: null,
        repeat: '',
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 20 || 'Name must be less than 20 characters',
      ],
      organizationRules: [
        (v) => !!v || 'Organization is required',
      ],
      datetimeRules: [
        (v) => !!v || 'Datetime is required',
      ],
      selectedDeviceId: '',
      selectedCommandId: '',
      devices: [],
      deviceMeta: {},
      commands: [],
      deviceCommands: [],
      repeatOptions: ['daily', 'weekly', 'monthly'],
    };
  },

  computed: {
    ...mapGetters(['organizations']),

    actionTableData() {
      return this.form.actions.map((action, index) => {
        const selectedDevice = this.devices.find((device) => action.device_id === device.id);
        const selectedCommand = this.commands.find((command) => action.command_id === command.id);
        return {
          device: selectedDevice,
          command: selectedCommand,
          id: index,
        };
      });
    },
  },

  watch: {
    dialog(state) {
      if (state) {
        this.$store.dispatch('getOrganizations');
        this.getDevices();
        this.getCommands();
      }
    },
    selectedDeviceId(deviceId) {
      if (deviceId) {
        this.getDeviceCommands(deviceId);
      }
    },
    'form.repeat': function (repeat) {
      switch (repeat) {
        case null:
          this.form.interval = null;
          this.form.frequency = null;
          break;
        case 'hourly':
          this.form.interval = 1;
          this.form.frequency = 'hours';
          break;
        case 'daily':
          this.form.interval = 1;
          this.form.frequency = 'days';
          break;
        case 'weekly':
          this.form.interval = 1;
          this.form.frequency = 'weeks';
          break;
        case 'monthly':
          this.form.interval = 1;
          this.form.frequency = 'months';
          break;

        default:
          break;
      }
    },
    'form.start_date': function (val) {
      // workaround reset datetime picker
      if (!val) {
        this.$refs.datetimePicker.clearHandler();
      }
    },
  },

  methods: {
    addData() {
      return this.$api.addSchedule(this.formData);
    },

    updateData() {
      return this.$api.updateSchedule({
        id: this.form.id,
        ...this.formData,
      });
    },

    async getDevices() {
      try {
        const { data } = await this.$api.getDevices();
        this.devices = data.data;
        this.deviceMeta = data.meta;
      } catch (error) {
        console.log(error);
      }
    },

    async getCommands(deviceId) {
      try {
        const { data } = await this.$api.getCommands(deviceId);
        this.commands = data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async getDeviceCommands(deviceId) {
      try {
        const { data } = await this.$api.getDeviceCommands(deviceId);
        this.deviceCommands = data.data;
      } catch (error) {
        console.log(error);
      }
    },

    addBucketItem() {
      if (!this.selectedDeviceId || !this.selectedCommandId) {
        return;
      }
      const actionIndex = this.form.actions.findIndex((act) => act.device_id === this.selectedDeviceId);
      const isActionInBucket = actionIndex !== -1;
      if (isActionInBucket) {
        this.form.actions.splice(actionIndex, 1);
      }
      this.form.actions.push({
        device_id: this.selectedDeviceId,
        command_id: this.selectedCommandId,
      });
      this.selectedDeviceId = null;
      this.selectedCommandId = null;
    },

    deleteBucketItem(action) {
      this.form.actions = this.form.actions.filter((act) => act.device_id !== action.device.id);
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.form = { ...this.defaultForm };
      this.form.actions = [];
    },
  },
};
</script>
